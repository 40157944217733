import { Box, Button, MenuItem, Select, Typography } from "@material-ui/core";
import { isNull, isUndefined } from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { ageDistributionPercentagesBelgium } from "../services/ageDistribution";
import {
  dateAfter,
  endDateProjection,
  remainingNumberOfDaysProjection,
  Vaccination,
} from "../services/vaccinations";
import { Error } from "./error";
import { ProgressCircle } from "./progress-circle";

const Container = styled.div`
  margin-bottom: 1rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const Dropdown = styled(Select)`
  margin: 1rem auto;
  width: 12rem;
`;

const Logo = styled.img`
  height: 40vmin;
  pointer-events: none;
`;

const TextContainer = styled(Box)`
  margin: 0.5rem 0;
`;

const SubtleTypography = styled(Typography)`
  color: darkgray;
  line-height: 1.7;
`;

const VaccinateTimingButton = styled(Button)`
  max-width: 20rem;
  margin: 2rem auto;
`;

const calculatePersonalTiming = (totalRemainingNumberOfDays: number) => {
  const age = Number(prompt("How old are you?"));

  if (isNaN(age) || age <= 0) {
    alert("That's not a valid age");
    return;
  }

  const percentageBeforeYou = Object.entries(
    ageDistributionPercentagesBelgium
  ).reduce(
    (acc, [range, percentage]) =>
      age < +range.substr(0, 2) ? acc + percentage : acc,
    0
  );

  alert(
    `${percentageBeforeYou.toFixed(
      2
    )}% of the population will get vaccinated before you. At the current pace, your turn will be around ${dateAfter(
      totalRemainingNumberOfDays * (percentageBeforeYou / 100)
    ).toLocaleDateString()}.`
  );
};

export const LocationsIndividual: FunctionComponent<{
  vaccinations: Vaccination[];
}> = ({ vaccinations }) => {
  const [vaccination, setVaccination] = useState<
    Vaccination | null | undefined
  >(undefined);

  useEffect(() => {
    vaccinations.length > 0 &&
      setVaccination(
        vaccinations.find(({ location }) => location === "Belgium") || null
      );
  }, [vaccinations]);

  if (isUndefined(vaccination)) {
    return null;
  }

  if (isNull(vaccination)) {
    return <Error />;
  }

  const { location, peopleVaccinatedPerHundred, flagUrl } = vaccination;

  return (
    <Container>
      <Dropdown
        value={location}
        onChange={({ target: { value } }) => {
          setVaccination(
            vaccinations.find(({ location }) => location === value)!
          );
        }}
      >
        {vaccinations.map(vaccination => (
          <MenuItem key={vaccination.location} value={vaccination.location}>
            {vaccination.location}
          </MenuItem>
        ))}
      </Dropdown>
      <Logo src={flagUrl} alt="flag"></Logo>
      <TextContainer>
        <ProgressCircle percent={peopleVaccinatedPerHundred} />
        <span> of people are vaccinated</span>
      </TextContainer>
      {vaccination.dailyVaccinationsPerMillion ? (
        <TextContainer>
          <SubtleTypography variant="subtitle1">
            {remainingNumberOfDaysProjection(vaccination)} more days 'till{" "}
            <ProgressCircle percent={100} smallFont /> at current pace:{" "}
            {endDateProjection(vaccination).toLocaleDateString()}
          </SubtleTypography>
        </TextContainer>
      ) : null}
      {vaccination.location === "Belgium" ? (
        <VaccinateTimingButton
          variant="contained"
          color="primary"
          onClick={() =>
            calculatePersonalTiming(
              remainingNumberOfDaysProjection(vaccination)
            )
          }
        >
          When will I get vaccinated?
        </VaccinateTimingButton>
      ) : null}
    </Container>
  );
};
