import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { sortBy } from "lodash";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Vaccination } from "../services/vaccinations";
import { ProgressLinear } from "./progress-linear";

const ScrollList = styled(List)`
  overflow: scroll;
  margin-bottom: 2.3rem;
`;

export const LocationsList: FunctionComponent<{
  vaccinations: Vaccination[];
}> = ({ vaccinations: [world, ...rest] }) => (
  <>
    <ScrollList>
      {[
        {
          ...world,
          peopleVaccinatedPerHundred: +(
            rest.reduce(
              (acc, next) => (acc += next.peopleVaccinatedPerHundred),
              0
            ) / rest.length
          ).toFixed(2),
        },
        ...sortBy(rest, ["peopleVaccinatedPerHundred", "location"]).reverse(),
      ].map(({ location, peopleVaccinatedPerHundred, flagUrl }, index, arr) => (
        <div key={location}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={`Flag of ${location}`} src={flagUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={location}
              secondary={
                <ProgressLinear percent={peopleVaccinatedPerHundred} />
              }
            />
          </ListItem>
          {index !== arr.length - 1 && (
            <Divider variant="inset" component="li" />
          )}
        </div>
      ))}
    </ScrollList>
  </>
);
