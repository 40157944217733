export const ageDistributionBelgium = {
  "100+": 1794,
  "95-99": 22534,
  "90-94": 93069,
  "85-89": 217742,
  "80-84": 321648,
  "75-79": 377292,
  "70-74": 548793,
  "65-69": 625194,
  "60-64": 725533,
  "55-59": 801530,
  "50-54": 792686,
  "45-49": 769461,
  "40-44": 738666,
  "35-39": 750715,
  "30-34": 745163,
  "25-29": 741263,
  "20-24": 669970,
  "15-19": 635445,
  "10-14": 668397,
  "05-09": 663924,
  "00-04": 608732,
};

export const ageDistributionPercentagesBelgium = {
  "00-04": 5.28,
  "05-09": 5.76,
  "10-14": 5.8,
  "15-19": 5.51,
  "20-24": 5.81,
  "25-29": 6.43,
  "30-34": 6.46,
  "35-39": 6.51,
  "40-44": 6.41,
  "45-49": 6.67,
  "50-54": 6.88,
  "55-59": 6.95,
  "60-64": 6.29,
  "65-69": 5.42,
  "70-74": 4.76,
  "75-79": 3.27,
  "80-84": 2.79,
  "85-89": 1.89,
  "90-94": 0.8,
  "95-99": 0.19,
  "100+": 0.01,
};
