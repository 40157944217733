import axios from "axios";
import csv from "csvtojson";

export interface Vaccination {
  dailyVaccinations: number;
  dailyVaccinationsPerMillion: number;
  dailyVaccinationsRaw: number;
  date: Date;
  isoCode: string;
  flagUrl: string;
  location: string;
  peopleFullyVaccinated: number;
  peopleFullyVaccinatedPerHundred: number;
  peopleVaccinated: number;
  peopleVaccinatedPerHundred: number;
  totalVaccinations: number;
  totalVaccinationsPerHundred: number;
}

const flagFor = (countryCode: string) => {
  const correctionMap: { [key: string]: string } = {
    EU: "european_union",
    TUR: "tr",
    POL: "pl",
    SWE: "se",
    DNK: "dk",
    AND: "ad",
  };

  const circleFlagCode =
    correctionMap[countryCode] || countryCode.toLowerCase().substring(0, 2);

  return ["", "OWID_WRL", "SHN", "WLD"].includes(countryCode)
    ? "https://upload.wikimedia.org/wikipedia/commons/c/c2/GDJ-World-Flags-Globe.svg"
    : `https://hatscripts.github.io/circle-flags/flags/${circleFlagCode}.svg`;
};

const toVaccination = (data: any): Vaccination => ({
  dailyVaccinations: Number(data.daily_vaccinations),
  dailyVaccinationsPerMillion: Number(data.daily_vaccinations_per_million),
  dailyVaccinationsRaw: Number(data.daily_vaccinations_raw),
  date: new Date(data.date),
  isoCode: data.iso_code,
  flagUrl: flagFor(data.iso_code),
  location: data.location,
  peopleFullyVaccinated: Number(data.people_fully_vaccinated),
  peopleFullyVaccinatedPerHundred: Number(
    data.people_fully_vaccinated_per_hundred
  ),
  peopleVaccinated: Number(data.people_vaccinated),
  peopleVaccinatedPerHundred: Number(data.people_vaccinated_per_hundred),
  totalVaccinations: Number(data.total_vaccinations),
  totalVaccinationsPerHundred: Number(data.total_vaccinations_per_hundred),
});

const EMPTY_VACCINATION = {
  dailyVaccinations: 0,
  dailyVaccinationsPerMillion: 0,
  dailyVaccinationsRaw: 0,
  date: new Date(),
  isoCode: "xx",
  flagUrl: flagFor("xx"),
  location: "",
  peopleFullyVaccinated: 0,
  peopleFullyVaccinatedPerHundred: 0,
  peopleVaccinated: 0,
  peopleVaccinatedPerHundred: 0,
  totalVaccinations: 0,
  totalVaccinationsPerHundred: 0,
};

export const getVaccinations = async () =>
  axios
    .get(
      "https://raw.githubusercontent.com/owid/covid-19-data/master/public/data/vaccinations/vaccinations.csv"
    )
    .then(({ data }) => csv().fromString(data))
    .then(unmappedList =>
      unmappedList
        .filter(
          (val, index, arr) =>
            !!arr[index + 1] && val.location !== arr[index + 1].location
        )
        .map(toVaccination)
    )
    .then(mappedList => [
      {
        ...EMPTY_VACCINATION,
        location: "World",
        isoCode: "WLD",
        flagUrl: flagFor("WLD"),
        peopleVaccinatedPerHundred: +(
          mappedList.reduce(
            (acc, { peopleVaccinatedPerHundred }) =>
              acc + peopleVaccinatedPerHundred,
            0
          ) / mappedList.length
        ).toFixed(2),
      },
      ...mappedList,
    ]);

export const remainingNumberOfDaysProjection = (vaccination: Vaccination) => {
  const totalAmountToVaccinate =
    (vaccination.totalVaccinations / vaccination.totalVaccinationsPerHundred) *
    100;
  const amountStillToVaccinate =
    totalAmountToVaccinate - vaccination.totalVaccinations;
  return Math.round(amountStillToVaccinate / vaccination.dailyVaccinations);
};

export const endDateProjection = (vaccination: Vaccination) => {
  const projectedDate = new Date();
  projectedDate.setDate(
    projectedDate.getDate() + remainingNumberOfDaysProjection(vaccination)
  );
  return projectedDate;
};

export const dateAfter = (days: number) => {
  const projectedDate = new Date();
  projectedDate.setDate(projectedDate.getDate() + days);
  return projectedDate;
};
