import { Box, CircularProgress, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import styled from "styled-components";

const Container = styled(Box)`
  transform: translateY(1rem);
`;

export const ProgressCircle: FunctionComponent<{
  percent: number;
  smallFont?: boolean;
}> = ({ percent, smallFont = false }) => (
  <Container position="relative" display="inline-flex">
    <CircularProgress variant="determinate" value={percent} size="3rem" />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant={"body1"}
        component="div"
        color="textSecondary"
        style={{ fontSize: smallFont ? "0.7rem" : "1rem" }}
      >{`${percent > 10 ? Math.round(percent) : percent}%`}</Typography>
    </Box>
  </Container>
);
