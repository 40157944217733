import ViewListIcon from "@material-ui/icons/ViewList";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import { ToggleButtonGroup } from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { getVaccinations, Vaccination } from "../services/vaccinations";
import { LocationsIndividual } from "../shared/locations-individual";
import { LocationsList } from "../shared/locations-list";

const Toggles = styled(ToggleButtonGroup)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

enum VIEW {
  list = "list",
  individual = "individual",
}

export const Locations: FunctionComponent = () => {
  const [view, setView] = useState(VIEW.individual);
  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);
  useEffect(() => {
    getVaccinations().then(vaccinations => setVaccinations(vaccinations));
  }, []);

  return (
    <>
      <Toggles
        orientation="vertical"
        value={view}
        exclusive
        onChange={(_, val) => setView(val)}
      >
        <ToggleButton value={VIEW.list} aria-label="list">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton value={VIEW.individual} aria-label="quilt">
          <ViewQuiltIcon />
        </ToggleButton>
      </Toggles>
      {
        {
          [VIEW.individual]: (
            <LocationsIndividual vaccinations={vaccinations} />
          ),
          [VIEW.list]: <LocationsList vaccinations={vaccinations} />,
        }[view]
      }
    </>
  );
};
