import { Button, ButtonGroup } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div<{ background: string }>`
  display: flex;
  flex: 1 1 auto;
  padding: 0 1rem;
  text-align: center;
  background-color: ${({ background }) => background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const Buttons = styled(ButtonGroup)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
`;

const WhiteLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

export const Layout: FunctionComponent = ({ children }) => {
  let location = useLocation();
  const [background, setBackground] = useState("#282c34");
  const [home, vaccinations, map, widget] = [
    "/",
    "/vaccinations",
    "/map",
    "/widget",
  ];
  useEffect(() => {
    setBackground(
      {
        [home]: "#282c34",
        [vaccinations]: "#00bcd4",
        [map]: "#a7a7a7",
        [widget]: "#47494d",
      }[location.pathname]
    );
  }, [location, home, vaccinations, map, widget]);
  return (
    <>
      <Container background={background}>{children}</Container>
      <Buttons color="primary">
        <Button variant={location.pathname === home ? "contained" : "text"}>
          <WhiteLink to={home}>Locations</WhiteLink>
        </Button>
        <Button
          variant={location.pathname === vaccinations ? "contained" : "text"}
        >
          <WhiteLink to={vaccinations}>Centers (BE)</WhiteLink>
        </Button>
        <Button variant={location.pathname === map ? "contained" : "text"}>
          <WhiteLink to={map}>Map</WhiteLink>
        </Button>
        {/* <Button variant={location.pathname === widget ? "contained" : "text"}>
          <WhiteLink to={widget}>Widget</WhiteLink>
        </Button> */}
      </Buttons>
    </>
  );
};
