import { FunctionComponent } from "react";

export const Map: FunctionComponent = () => (
  <iframe
    title="World Map"
    src="https://ourworldindata.org/grapher/covid-vaccination-doses-per-capita?stackMode=absolute&time=latest&region=World"
    loading="lazy"
    style={{ width: "100%", height: "600px", border: "0px none" }}
  ></iframe>
);
