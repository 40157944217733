import { Box, LinearProgress, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";

export const ProgressLinear: FunctionComponent<{ percent: number }> = ({
  percent,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={percent} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
        >{`${percent}%`}</Typography>
      </Box>
    </Box>
  );
};
