import { FunctionComponent } from "react";
import styled from "styled-components";

const Frame = styled.iframe`
  border: none;
  width: 80vw;
  height: 60vw;
`;

export const Vaccinations: FunctionComponent = () => (
  <Frame src="https://datawrapper.dwcdn.net/aJIMp/3/"></Frame>
);
