import { Paper, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { FunctionComponent } from "react";
import styled from "styled-components";

const Container = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3737;
  padding: 1rem;
`;

const Icon = styled(ErrorIcon)`
  margin-right: 0.5rem;
`;

export const Error: FunctionComponent = () => (
  <Container>
    <Icon fontSize="large" />
    <Typography variant="h5">
      Something is wrong with the data source, this will be fixed as soon as
      possible!
    </Typography>
  </Container>
);
