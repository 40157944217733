import { StylesProvider, ThemeProvider } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Locations } from "./screens/locations";
import { Map } from "./screens/map";
import { Vaccinations } from "./screens/vaccinations";
import { theme } from "./services/theme";
import { Layout } from "./shared/layout";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Router>
          <Layout>
            <Switch>
              {/* <Route path="/widget">
                <Widget />
              </Route> */}
              <Route path="/map">
                <Map />
              </Route>
              <Route path="/vaccinations">
                <Vaccinations />
              </Route>
              <Route path="/">
                <Locations />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
